/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #ffffff;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin-right: 40px;
  font-size: 20px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #ff0000;
}

.main-content {
  background-color: #fff;
  color: #333;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dark-mode-toggle {
  background-color: #222;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-mode-toggle {
  background-color: #ffffff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
  background-color: #000;
}

.dark-mode {
  background-color: #222;
  color: #fff;
}

.lidark {
  color: white;
}

.liclear {
  color: rgb(41, 40, 40);
}

.whatsapp-button {
  position: fixed;
  bottom: 20px; /* Ajuste para centralizar verticalmente */
  left: 43.2%; /* Centraliza horizontalmente */
  transform: translateX(-50%); /* Centraliza horizontalmente */
  background-color: #25d366;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shake 0.5s infinite alternate; /* Animação de vibração */
}

/* Remover a animação quando o botão está em estado de hover */
.whatsapp-button:hover {
  position: fixed;
  bottom: 20px; /* Ajuste para centralizar verticalmente */
  left: 43.2%; /* Centraliza horizontalmente */
  transform: translateX(-50%); /* Centraliza horizontalmente */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shake 0.5s infinite alternate; /* Animação de vibração */
}


.whatsapp-icon {
  width: 24px;
  height: 24px;
  background-image: url('./images/whatsapp-icon.png'); /* Adicione o ícone do WhatsApp */
  background-size: cover;
  margin-right: 5px;
}

.bannerSite {
  width: 100%;
}

/* App.css */

/* Adicionando estilos para os links dos contratos */
.main-content a {
  display: block; /* Alterado de inline-block para block */
  width: 200px; /* Largura dos botões */
  margin: 10px auto; /* Margem automática para centralizar os botões */
  padding: 15px 20px; /* Espaçamento interno dos botões */
  background-color: #0b121a; /* Cor de fundo dos links */
  color: #fff; /* Cor do texto dos links */
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  text-align: center; /* Centralizar texto dentro dos botões */
  transition: background-color 0.3s ease;
}

/* Estilo para os links dos contratos quando o mouse passa por cima */
.main-content a:hover {
  background-color: #ec3939; /* Cor de fundo dos links ao passar o mo*/
}





@keyframes shake {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

@media only screen and (max-width: 600px) {
  header {
    flex-direction: column;
    padding: 10px;
  }

  nav {
    margin-top: 10px;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 5px 0;
  }

  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 16%;
    transform: translateX(-50%);
    background-color: #25d366;
    color: #fff;
    border: none;
    padding: 10px 40px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shake 0.5s infinite alternate;
  }

  /* Adicionando estilos para os links dos contratos em dispositivos móveis */
  .main-content a {
    width: auto;
  }
}
